@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --gray: #cccccc;
    --rgba-black: #00000080;
    --rgba-blue: #00000080; 
    --red: #a52a2a;
    --primary: #f6f6f6,
}

::-webkit-scrollbar {
    width: 1px;
    height: 8px;
    cursor: pointer;
  }
  
  /* Couleur de fond de la barre de défilement */
  /* Il s'agit de la partie non visible de la barre de défilement */
  ::-webkit-scrollbar-track {
    background-color: #eee;
  }
  
  /* Couleur de la poignée de défilement (le curseur) */
  ::-webkit-scrollbar-thumb {
    background-color: #f6f6f6;
  }
  
  /* Changement de couleur de la poignée de défilement lorsqu'elle est survolée */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }


.Toastify__toast--success{
  background-color: brown;
}

.Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--success.Toastify__toast--close-on-click{
  background-color: green;
}

.Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--error.Toastify__toast--close-on-click{
  background-color: brown;
}

/* Style des tableaux */
/* Style des tableaux */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}


thead{
  background-color: var(--primary);
  color: var(--white);
  font-size: 16px;
}


tr:nth-child(even) {
    background-color: var(--gray);
}

th, td {
  text-align: left;
  padding: 8px;
}


td{
  font-size: 13px;
  white-space: nowrap;
}

th {
  font-size: 13px;
}
/* 
th::after {
  cursor: pointer;
  content: "";
} */

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/* Charts styles */
/* #chart_pie .apexcharts-legend.apexcharts-align-center.apx-legend-position-right{
  width: 100%;
  position: relative;
  background-color: #a52a2a;
} */


/* #chart_pie #apexchartsyno51kg2 svg g{
  background-color: aqua;
  display: flex;
  flex-direction: row;
  width: 500px;
  font-size: 100px;
}

#apexchartsfl0onz3c{
  display: flex;
  flex-direction: row;
}

#chart_pie{
  width: 100%;
} */